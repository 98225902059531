//後端地址

 const baseURL= process.env.VUE_APP_BASE_URL //綫上地址
// const baseURL = 'http://192.168.51.17:121/saas-dev' //杨东IP地址
// const baseURL = 'http://192.168.51.22:121/saas-dev' //董国庆IP 地址 YuanYu
// const baseURL =  'http://192.168.51.10:121/saas-dev' //张越IP 地址 YuanYu
// const baseURL =  'http://192.168.51.10:121/saas-dev' //张越IP 地址 YuanYu
// const baseURL = 'https://csipre.yyology.com/saas' //正式地址
// const baseURL = 'http://1.117.144.208:86/saas' //pre地址
// const baseURL = 'http://221.226.31.102:100/saas-test' //test地址


// const wsURL = 'ws://192.168.51.22:121/saas-dev' //董国庆IP 地址 YuanYu
const wsURL = process.env.VUE_APP_WS_URL //线上webSocket地址


//跳转至后后台管理地址（房建）
// const manageURL = "http://192.168.31.103:3000" //本地地址
const  manageURL= process.env.VUE_APP_MANAGE_URL //线上地址
//跳转至后后台管理地址（水利）
// const waterURL = "http://192.168.31.103:3000" //本地地址
const  waterURL= process.env.VUE_APP_WATER_URL //线上地址
//跳转至项目大屏地址
// const projectURL = " http://192.168.51.14:8088/workSite/#/" //本地地址
const  projectURL= process.env.VUE_APP_ONLINE_BASE_URL //线上地址



export default {
    waterURL,
    manageURL,
    projectURL,
    baseURL,
    wsURL
}
