import request from '@/api/request'

// 获取项目基本信息(首页轮播图,项目名称,五方单位等等)
export function getProjectMessage(projectId) {
    return request({
        url: `csi/proDashboard/homepage/proIntroduce/${projectId}`,
        method: 'get'
    })
}
//文明施工轮播图
export function getCivilizedList(data) {
    return request({
        url: `/csi/proDashboard/homepage/getCivilizedList`,
        method: 'get',
        params: data,

    })
}

//今日进出场人数
export function getTrend(projectId) {
    return request({
        url: `/csi/proDashboard/homepage/trend/${projectId}`,
        method: 'get'
    })
}
//人员进出记录
export function getInOut(projectId,num) {
    return request({
        url: `/csi/proDashboard/homepage/inOut/${projectId}/${num}`,
        method: 'get',
    })
}
//项目工期
export function getProDate(projectId) {
    return request({
        url: `/csi/proDashboard/homepage/proDate/${projectId}`,
        method: 'get'
    })
}
//安全预警已解除和未解除的数量
export function getSafetyEventNum(projectId) {
    return request({
        url: `/csi/proDashboard/homepage/safetyEventNum/${projectId}`,
        method: 'get'
    })
}
//设备下拉
export function getdeviceDown(projectId,type) {
    return request({
        url: `/csi/proDashboard/homepage/dropDown/${projectId}/${type}`,
        method: 'get',
    })
}
//环境监测.设备数据+ 国控点数据 0:设备数据， 1:国控点数据
export function getEnvironment(projectId,deviceId) {
    return request({
        url: `/csi/proDashboard/homepage/environment/${projectId}/${deviceId}`,
        method: 'get',
    })
}
//安全检查(移动巡检，隐患随手拍，项目自查数量)
export function getSafetyCheck(projectId) {
    return request({
        url: `/csi/proDashboard/homepage/safetyCheck/${projectId}`,
        method: 'get',
    })
}
//视频树形下拉
export function getVideoTree(projectId,type) {
    return request({
        url: `/csi/proDashboard/video/videoTree/${projectId}/${type}`,
        method: 'get'
    })
}
// 国标视频切换回放
export function gbPlaybackUrl(data) {
    return request({
        url: `csi/proDashboard/video/gbPlaybackUrl`,
        method: 'get',
        params: data,
    })
}

//获取数据字典(全部)
export function getAllDict() {
    return request({
        url: `/csi/proDashboard/homepage/allDict`,
        method: 'get',
    })
}
//获取路由配置
export function getProjectRoute(data) {
    return request({
        url: `/csi/proDashboard/homepage/getProjectRoute`,
        method: 'get',
        params: data,
    })
}
//项目打分下拉
export function getScoreDown(data) {
    return request({
        url: `/csi/pro/recommend/getScoreDown`,
        method: 'get',
        params: data,
    })
}

// 查询打分表一级菜单
export function oneLevelMenu(data) {
    return request({
        url: `csi/pro/recommend/getScoreTitleList`,
        method: 'get',
        params: data,
    })
}

// 查询打分表详细数据
export function menuList(data) {
    return request({
        url: `csi/pro/recommend/getScoreList`,
        method: 'get',
        params: data,
    })
}
